.help-section-container {
  margin-right: 0;
  margin-left: 0;
  background-color: #ffffff;
}

.help-section-main {
  padding-left: 0;
  padding-right: 0;
  padding: 10.703% 8.59% 6.25% 8.5156%;
}

.help-header {
  gap: 60px;
}

.help-header h1 {
  color: #000;
  font-family: Lato;
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: 51px; /* 100% */
}

.help-header h2 {
  font-size: 16px;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  line-height: 30px;
}

.help-contact-header h3 {
  font-size: 25px;
  font-family: Lato;
  font-weight: 600;
  line-height: 30px;
}

.help-contact-header .MuiButtonBase-root {
  min-width: 24px;
  padding-right: 0;
}

.contact-main {
  margin-bottom: 80px;
}

.contact-main .MuiButtonBase-root {
  width: 188px;
  height: 57px;
  background-color: rgba(255, 211, 1, 1);
  border-radius: 33px;
  color: rgba(19, 19, 19, 1);
}

.contact-main .MuiButtonBase-root:hover {
  background-color: rgba(19, 19, 19, 1);
  color: rgba(255, 211, 1, 1);
}

.contact-main .MuiButtonBase-root span {
  font-size: 17px;
  font-family: "Lato", sans-serif;
  font-weight: 700;
  line-height: 17px;
}

.name-email-container {
  gap: 32px;
  margin-bottom: 34px;
}

.name-field,
.email-field,
.queries-field {
  width: 100%;
}

.queries-field {
  min-height: 287px;
}

.queries-container {
  margin-bottom: 44px;
}

.name-field,
.email-field,
.queries-field {
  background-color: rgba(235, 235, 235, 1);
  border-radius: 10px;
}

.name-field .MuiInputBase-root,
.email-field .MuiInputBase-root,
.queries-field .MuiInputBase-root {
  border-radius: 10px;
  border: 0;
}

.name-field .MuiInputBase-root .MuiOutlinedInput-notchedOutline,
.email-field .MuiInputBase-root .MuiOutlinedInput-notchedOutline,
.queries-field .MuiInputBase-root .MuiOutlinedInput-notchedOutline {
  border: 0;
}

.faq-heading h1 {
  font-size: 43px;
  font-family: "Inter", sans-serif;
  font-weight: 700;
  line-height: 43px;
  margin-bottom: 24px;
}

.faq-card {
  border-bottom: 1px solid rgba(102, 102, 102, 0.2);
  margin-bottom: 24px;
}

.faq-card h3 {
  font-size: 20px;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  line-height: 36px;
  margin-bottom: 12px;
  color: rgba(0, 0, 0, 1);
}

.faq-card p {
  font-size: 18px;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  line-height: 30px;
  margin-bottom: 24px;
  color: rgba(102, 102, 102, 1);
}

.faq-card .MuiButtonBase-root {
  min-width: 24px;
  padding-right: 0;
}

.hidden {
  display: none;
}

@media (max-width: 767px) {
  .help-header img {
    width: 100%;
  }
}

@media (max-width: 1280px) {
  .help-header img {
    width: 100%;
  }
}
