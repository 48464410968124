@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;700&family=Lato:ital,wght@0,300;0,400;0,700;1,300;1,400&family=Montserrat:ital,wght@0,400;0,600;0,700;1,300&family=Nunito+Sans:ital,opsz,wght@0,6..12,300;0,6..12,400;0,6..12,500;1,6..12,400&family=Nunito:wght@300;400&family=Poppins:wght@200&family=Roboto&family=Roboto+Condensed:wght@700&family=Rubik&family=Syne:wght@400;500;600;700&family=Urbanist:wght@200;300;400;500;600;700&display=swap");

.first-section-container {
  display: block;
  padding-top: 15%;
  padding-left: 0%;
  padding-right: 0%;
  padding-bottom: 0%;
  background: linear-gradient(283deg, #ffc100 12.02%, #ffe8a0 94.83%),
    lightgray 0px -60.627px / 100% 114.25% no-repeat;
}
.first-section-mobile-container {
  display: none;
}

.home-image {
  display: block;
  width: 100%;
}

.home-image-mob {
  display: none;
}

.first-section-container h1 {
  // position: absolute;
  // margin-top: -38%;
  // width: 50%;
  padding-bottom: 5%;
  padding-left: 10%;
  color: #00030a;
  font-family: Montserrat;
  font-size: 53.65px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 57.6px */
}

.first-section-container h2 {
  // position: absolute;
  // margin-top: -28%;
  // width: 40%;
  padding-left: 10%;
  padding-bottom: 15%;
  color: #000;
  font-family: Montserrat;
  font-size: 17.66px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 24px */
}

.first-section-container h3 {
  // position: absolute;
  margin-top: -24%;
  width: 40%;
  padding-left: 10%;
  color: #000;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 24px */
}

.favhiker-embed-video {
  // position: absolute;
  // margin-top: 12%;
  width: 55%;
  padding-left: 10%;
  right: 6%;
  // padding-top: 30%;
  padding-bottom: 15%;
}

// .first-section-container h3 {
//   position: absolute;
//   margin-top: -22%;
//   width: 55%;
//   padding-left: 10%;
//   color: #fff;
//   font-family: Lato;
//   font-size: 16px;
//   font-style: normal;
//   font-weight: 400;
//   line-height: normal;
//   text-transform: capitalize;
// }

.first-section-buttons {
  // position: absolute;
  // margin-top: -15%;
  padding-left: 10%;
  display: flex;
  flex-direction: row;
  gap: 4%;
  justify-content: flex-start;
  align-items: flex-start;
}

.IosButton {
  cursor: pointer;
  float: left;
}

.PlayStoreButton {
  cursor: pointer;
  float: left;
}

@media (max-width: 767px) {
  .first-section-mobile-container {
    background: linear-gradient(283deg, #ffc100 12.02%, #ffe8a0 94.83%),
      lightgray 0px -37.892px / 100% 114.25% no-repeat;
    display: block;
    padding-top: 10%;
    padding-bottom: 10%;
  }
  .first-section-container {
    display: none;
    padding-top: 6%;
    padding-left: 0%;
    padding-right: 0%;
  }

  .home-image {
    display: none;
  }

  .home-image-mob {
    display: block;
    width: 100%;
  }

  .first-section-mobile-text h1 {
    padding-top: 20%;
    width: 100%;
    padding-left: 10%;
    color: #000000;
    font-family: Roboto Condensed;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 102.5%;
    letter-spacing: 6.4px;
  }

  .home-image {
    width: 100%;
  }

  .first-section-mobile-text h2 {
    padding-top: 10%;
    width: 100%;
    padding-left: 10%;
    color: rgb(0, 0, 0);
    font-family: Lato;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: capitalize;
  }

  .first-section-mobile-text h3 {
    padding-top: 10%;
    width: 70%;
    padding-left: 10%;
    color: rgb(0, 0, 0);
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: capitalize;
  }

  .favhiker-mobile-embed-video {
    padding-top: 10%;
    width: 100%;
    padding-left: 10%;
    padding-right: 10%;
    right: 6%;
  }

  .first-section-mobile-buttons {
    padding-left: 10%;
    padding-top: 10%;
  }

  .IosButton {
    cursor: pointer;
    float: left;
  }

  .PlayStoreButton {
    cursor: pointer;
    float: left;
  }
}

@media (min-width: 767px) and (max-width: 1280px) {
  .first-section-mobile-container {
    background: linear-gradient(283deg, #ffc100 12.02%, #ffe8a0 94.83%),
      lightgray 0px -37.892px / 100% 114.25% no-repeat;
    display: block;
    padding-top: 10%;
    padding-bottom: 10%;
  }
  .first-section-container {
    display: none;
    padding-top: 2%;
    padding-left: 0%;
    padding-right: 0%;
  }

  .home-image {
    display: none;
  }

  .home-image-mob {
    display: block;
    width: 100%;
  }

  .first-section-mobile-text h1 {
    padding-top: 20%;
    width: 100%;
    padding-left: 10%;
    color: #000000;
    font-family: Roboto Condensed;
    font-size: 50px;
    font-style: normal;
    font-weight: 700;
    line-height: 102.5%;
    letter-spacing: 6.4px;
  }

  .home-image {
    width: 100%;
  }

  .first-section-mobile-text h2 {
    padding-top: 5%;
    width: 70%;
    padding-left: 10%;
    color: rgb(0, 0, 0);
    font-family: Lato;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: capitalize;
  }

  .first-section-mobile-text h3 {
    padding-top: 10%;
    width: 70%;
    padding-left: 10%;
    color: rgb(0, 0, 0);
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: capitalize;
  }

  .first-section-mobile-buttons {
    padding-left: 10%;
    padding-top: 5%;
  }

  .IosButton {
    cursor: pointer;
    float: left;
  }

  .PlayStoreButton {
    cursor: pointer;
    float: left;
  }
  .favhiker-mobile-embed-video {
    // position: absolute;
    // margin-top: 12%;

    padding-left: 10%;
    padding-right: 10%;
    right: 6%;
    // padding-top: 30%;
    padding-bottom: 10%;
  }
}

// @media (min-width: 1800px) {
//   .first-section-container {
//     display: block;
//     padding-top: 6%;
//     padding-left: 0%;
//     padding-right: 0%;
//     padding-bottom: 0%;
//   }
//   .first-section-mobile-container {
//     display: none;
//   }

//   .home-image {
//     display: block;
//     width: 100%;
//   }

//   .home-image-mob {
//     display: none;
//   }

//   .first-section-container h1 {
//     position: absolute;
//     margin-top: -38%;
//     width: 50%;
//     padding-left: 10%;
//     color: #00030a;
//     font-family: Montserrat;
//     font-size: 70px;
//     font-style: normal;
//     font-weight: 700;
//     line-height: 120%; /* 57.6px */
//   }

//   .first-section-container h2 {
//     position: absolute;
//     margin-top: -28%;
//     width: 40%;
//     padding-left: 10%;
//     color: #000;
//     font-family: Montserrat;
//     font-size: 32px;
//     font-style: normal;
//     font-weight: 400;
//     line-height: 150%; /* 24px */
//   }

//   .first-section-container h3 {
//     position: absolute;
//     margin-top: -24%;
//     width: 40%;
//     padding-left: 10%;
//     color: #000;
//     font-family: Montserrat;
//     font-size: 25px;
//     font-style: normal;
//     font-weight: 400;
//     line-height: 150%; /* 24px */
//   }

//   // .first-section-container h3 {
//   //   position: absolute;
//   //   margin-top: -22%;
//   //   width: 55%;
//   //   padding-left: 10%;
//   //   color: #fff;
//   //   font-family: Lato;
//   //   font-size: 16px;
//   //   font-style: normal;
//   //   font-weight: 400;
//   //   line-height: normal;
//   //   text-transform: capitalize;
//   // }

//   .first-section-buttons {
//     position: absolute;
//     margin-top: -15%;
//     padding-left: 10%;
//     display: flex;
//     flex-direction: row;
//     gap: 4%;
//     justify-content: flex-start;
//     align-items: flex-start;
//   }

//   .IosButton {
//     cursor: pointer;
//     float: left;
//   }

//   .PlayStoreButton {
//     cursor: pointer;
//     float: left;
//   }
// }

@media (min-width: 1801px) and (max-width: 2600px) {
  .first-section-container h1 {
    // position: absolute;
    // margin-top: -38%;
    // width: 50%;
    padding-bottom: 5%;
    padding-left: 10%;
    color: #00030a;
    font-family: Montserrat;
    font-size: 80.65px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%; /* 57.6px */
  }

  .first-section-container h2 {
    // position: absolute;
    // margin-top: -28%;
    // width: 40%;
    padding-left: 10%;
    padding-bottom: 15%;
    color: #000;
    font-family: Montserrat;
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 24px */
  }
}

@media (min-width: 2601px) and (max-width: 3400px) {
  .first-section-container h1 {
    // position: absolute;
    // margin-top: -38%;
    // width: 50%;
    padding-bottom: 5%;
    padding-left: 10%;
    color: #00030a;
    font-family: Montserrat;
    font-size: 100.65px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%; /* 57.6px */
  }

  .first-section-container h2 {
    // position: absolute;
    // margin-top: -28%;
    // width: 40%;
    padding-left: 10%;
    padding-bottom: 15%;
    color: #000;
    font-family: Montserrat;
    font-size: 40.66px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 24px */
  }
}
@media (min-width: 3401px) and (max-width: 5200px) {
  .first-section-container h1 {
    // position: absolute;
    // margin-top: -38%;
    // width: 50%;
    padding-bottom: 5%;
    padding-left: 10%;
    color: #00030a;
    font-family: Montserrat;
    font-size: 150.65px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%; /* 57.6px */
  }

  .first-section-container h2 {
    // position: absolute;
    // margin-top: -28%;
    // width: 40%;
    padding-left: 10%;
    padding-bottom: 15%;
    color: #000;
    font-family: Montserrat;
    font-size: 50.66px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 24px */
  }
}
// @media (min-width: 1801px) and (max-width: 5000px) {
//   .home-image {
//     display: block;
//     width: 100%;
//   }
// }
