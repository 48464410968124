.home-bg-container {
  // position: absolute;
  // top: 0;
  // left: 0;
  width: 100%;
  // height: 100vh;
}

.home-image {
  width: 100%;
}

@media (max-width: 767px) {
  // .home-bg-container {
  //   background-position: initial;
  // }
}
