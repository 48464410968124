.problem-solution-container {
  background: #ffffff;
  padding-top: 10%;
}

.problem-solution-mobile-container {
  display: none;
}

.problem-section-main {
  padding-bottom: 3%;
}

.problem-section-main h1 {
  color: #181818;
  font-family: Poppins;
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
  line-height: 48px; /* 133.333% */
  letter-spacing: 0.72px;
  text-align: center;
}

.problems-row {
  padding-bottom: 10%;

  padding-left: 8%;
  padding-right: 8%;
}

.problem-text {
  padding-left: 10%;
  padding-right: 10%;
}

.problem-text h1 {
  color: #181818;
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 100% */
  text-align: center;
}

.problem-text p {
  color: #5a5a5a;
  text-align: center;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 171.429% */
}

.problem-img {
  min-height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.solution {
  padding-bottom: 8%;
  padding-left: 10%;
  padding-right: 10%;
}

.solution-text {
  padding-top: 3%;
}

.solution-image {
  display: flex;
  align-items: center;
  justify-content: center;
}

.solution-text h1 {
  color: #181818;
  font-family: Poppins;
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
  line-height: 48px; /* 133.333% */
  letter-spacing: 0.72px;
}

.solution-text p {
  color: #5a5a5a;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 171.429% */
}

.cc {
  padding-bottom: 3%;
}

@media (max-width: 767px) {
  .problem-solution-mobile-container {
    display: block;
    background: #ffffff;
    padding-top: 10%;
    padding-left: 6.67%;
    padding-right: 6.67%;
  }
  .problem-solution-container {
    display: none;
  }

  .problem-section-main {
    padding-bottom: 3%;
  }

  .problem-section-main h1 {
    color: #181818;
    font-family: Poppins;
    font-size: 25px;
    font-style: normal;
    font-weight: 600;
    line-height: 35px; /* 133.333% */
    letter-spacing: 0.72px;
    text-align: start;
  }

  .problems-row {
    padding-bottom: 10%;

    padding-left: 8%;
    padding-right: 8%;
  }

  .problem-text {
    padding-left: 10%;
    padding-right: 10%;
    padding-bottom: 8%;
  }

  .problem-text h1 {
    color: #181818;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 100% */
    text-align: center;
  }

  .problem-text p {
    color: #5a5a5a;
    text-align: center;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 171.429% */
  }

  .problem-img {
    min-height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .solution {
    padding-bottom: 8%;
    padding-left: 10%;
    padding-right: 10%;
  }

  .solution-text {
    padding-top: 3%;
  }

  .solution-image {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .solution-text h1 {
    color: #181818;
    font-family: Poppins;
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px; /* 133.333% */
    letter-spacing: 0.72px;
  }

  .solution-text p {
    color: #5a5a5a;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 171.429% */
  }

  .cc {
    padding-bottom: 3%;
  }
}

@media (max-width: 1280px) {
}
