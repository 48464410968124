/* PrivacyPolicy.css */

.privacy-policy-container {
  padding-left: 12%;
  padding-right: 12%;

  padding-top: 10%;
  padding-bottom: 10%;
}

.title {
  color: #333;
  text-align: center;
}

.last-updated {
  color: #666;
  margin-bottom: 20px;
}

.intro {
  margin-bottom: 30px;
}

.summary {
  margin-bottom: 30px;
}

.table-of-contents {
  margin-bottom: 50px;
}

.data-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.data-table th,
.data-table td {
  border: 1px solid #ddd;
  padding: 8px;
}

.data-table th {
  background-color: #f2f2f2;
}
