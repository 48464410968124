.community-section-container {
  margin-right: 0;
  margin-left: 0;
  background: #ffffff;
}

.community-section-main {
  padding-left: 0;
  padding-right: 0;
  padding: 4.375% 8.59% 3.414% 10.859%;
  // margin-top: 439.16px;
}

.community-section-content {
  gap: 5.078%;
}

.community-section-text {
  width: 54.29%;
  height: 31.64%;
}

.community-section-text h1 {
  color: #000;
  font-family: Lato;
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: 71px; /* 139.216% */
}

.community-section-text h3 {
  color: #333;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 40px; /* 166.667% */
  text-transform: capitalize;
}

@media (max-width: 1280px) {
  .community-section-text {
    width: 100%;
    height: 31.64%;
  }
  .community-section-main {
    margin-top: 1000.16px;
  }
}

@media (max-width: 767px) {
  .community-section-main {
    margin-top: 50.16px;
  }
}
