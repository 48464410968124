.goldenbites-container {
  background-color: #050607;
  padding-top: 8%;
  padding-bottom: 8%;
}

.goldenbites-content {
  padding-top: 4%;
  padding-bottom: 3%;
  padding-left: 10%;
  padding-right: 10%;
}

.goldenbites-content h1 {
  color: #fff;
  // text-align: center;
  font-family: Lato;
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: 51px;
}
.goldenbites-content h1 span {
  background: linear-gradient(180deg, #ffc100 100%, #ffe8a0 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: Roboto;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 1;
  letter-spacing: 1.92px;
}

.goldenbites-content p {
  color: #fff;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
}

.goldenbites-content h2 {
  color: #fff;
  font-family: Poppins;
  font-size: 14px;
  font-style: italic;
  font-weight: 275;
  line-height: 32px; /* 228.571% */
}

.goldenbites-images {
  display: flex;
  flex-direction: row;
  gap: 5%;
}

.elavate-content {
  padding-top: 12%;
}

.elavate-content h1 {
  color: #fff;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px; /* 162.5% */
  letter-spacing: -0.48px;
  text-transform: uppercase;
  text-align: center;
}

.elavate-content p {
  color: #fff;
  text-align: center;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px; /* 162.5% */
}
.elevate-section {
  padding-left: 10%;
  padding-right: 10%;
}

.left-image-col {
  display: flex;
  align-items: center;
  justify-content: center;
}

.right-image-col {
  display: flex;
  align-items: center;
  justify-content: center;
}

.iphone-image-desktop {
  display: block;
}

.iphone-image-mobile {
  display: block;
}

.goldenbites-images-desktop {
  display: flex;
  justify-content: center;
  align-items: center;
}

.goldenbites-images-mobile {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 767px) {
  .goldenbites-container {
    background-color: #050607;
    padding-top: 8%;
    padding-bottom: 8%;
  }

  .goldenbites-content {
    padding-top: 4%;
    padding-bottom: 3%;
    padding-left: 10%;
    padding-right: 10%;
  }

  .goldenbites-heading {
    margin-bottom: 35px;
  }

  .goldenbites-content h1 {
    color: #fff;
    text-align: center;
    font-family: Lato;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    margin-bottom: 0;
    // line-height: 51px;
  }
  .goldenbites-content h1 span {
    background: linear-gradient(180deg, #ffc100 100%, #ffe8a0 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: Roboto;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 1;
    letter-spacing: 1.2px;
  }

  .goldenbites-content h2 {
    // text-align: center;
    color: #fff;
    font-family: Poppins;
    font-size: 14px;
    font-style: italic;
    font-weight: 275;
    line-height: 32px;
    margin-bottom: 0;
  }

  .goldenbites-content p {
    // text-align: center;
    color: #fff;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
  }

  .goldenbites-content p span {
    color: #fff;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 26px;
  }

  .elavate-content {
    padding-top: 12%;
  }

  .elavate-content h1 {
    color: #fff;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px; /* 162.5% */
    letter-spacing: -0.48px;
    text-transform: uppercase;
    text-align: center;
  }

  .elavate-content p {
    color: #fff;
    text-align: center;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px; /* 162.5% */
  }
  .elevate-section {
    padding-left: 10%;
    padding-right: 10%;
  }

  .elavate-content img {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .left-image-col {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .right-image-col {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .iphone-image-desktop {
    display: block;
  }

  .iphone-image-mobile {
    display: block;
  }

  .goldenbites-images-desktop {
    display: block;
  }

  .goldenbites-images-mobile {
    display: block;
    margin-bottom: 35px;
  }

  .goldenbites-images {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
