.footer-section-container {
  display: block;
  padding-top: 8%;
  padding-left: 10%;
  padding-right: 10%;
  margin-right: 0;
  margin-left: 0;
  background-color: rgba(0, 0, 0, 1);
  color: rgba(255, 255, 255, 1);
}

.mobile-footer-section-container {
  display: none;
}

.footer-section-main {
  padding-left: 0;
  padding-right: 0;
}

.footer-section-content {
  gap: 34px;
}

.delnie-logo-container.desktop-view-logo {
  padding-top: 0.5%;
  padding-bottom: 48px;
}

.address-delnie {
  padding-left: 0;
  padding-right: 0;
}

.address-delnie h5 {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 26px;
  color: #cdcdcd;
}

.address-delnie h4 {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  line-height: 18px;
  color: #cdcdcd;
  margin-bottom: 0;
}

.address-delnie span {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-weight: 300;
  line-height: 18px;
  color: #cdcdcd;
}

.address-bengaluru,
.address-mumbai {
  width: 37.5%;
}

.address-bengaluru-mobile {
  padding-bottom: 20.71px;
}

.address-delnie-mobile {
  padding-top: 5%;
  padding-bottom: 20.71px;
}

.address-delnie-mobile h5 {
  font-size: 12px;
}

.address-bengaluru-mobile h4 {
  font-size: 12px;
}

.address-delnie-mobile span {
  font-size: 12px;
}

.favhiker-description-container h1 {
  font-size: 36px;
  font-family: "Gugi", cursive;
  font-weight: 400;
  line-height: 39.6px;
  margin-bottom: 14px;
}

.favhiker-description-container p {
  font-size: 11px;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  line-height: 19px;
  margin-bottom: 25px;
  color: rgba(208, 207, 207, 1);
}

.favhiker-description-container {
  max-width: 85.9218%;
}

.favhiker-description-container h3 {
  font-size: 26px;
  font-family: "Gugi", cursive;
  font-weight: 400;
  line-height: 28.6px;
  margin-bottom: 12px;
}

.social-media-container {
  gap: 9.99px;
}

.social-media-container .MuiButtonBase-root {
  min-width: 39px;
  width: 39px;
  height: 39px;
  padding-left: 0;
}

.pages-container h4 {
  color: #fff;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px; /* 162.5% */
}

.pages-container h5 {
  font-size: 16px;
  font-family: "Rubik", cursive;
  font-weight: 400;
  line-height: 26px;
  margin-bottom: 10px;
  color: rgba(208, 207, 207, 1);
}

.info-container h4 {
  color: #fff;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px; /* 162.5% */
  text-align: center;
}

.info-container h5 {
  font-size: 16px;
  font-family: "Rubik", cursive;
  font-weight: 400;
  line-height: 26px;
  margin-bottom: 10px;
  color: rgba(208, 207, 207, 1);
}

.app-download-container {
  display: flex;
  flex-direction: column;
}

.app-download-container h3 {
  color: #fff;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px; /* 162.5% */
  text-align: center;
}

.app-download-container .MuiButtonBase-root {
  padding-left: 0;
}

.delnie-logo-container {
  background-color: black;
  padding-top: 8%;
  padding-bottom: 2%;
}

.no-text-decoration {
  text-decoration: none;
}

.IosButton-footer {
  padding-top: 20px;
  padding-bottom: 20px;
}

.social-media-row {
  padding-top: 8%;
}

.delnie-icons {
  padding-right: 1%;
}
.get-apps-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 1%;
}
@media (max-width: 1280px) {
  .favhiker-description-container {
    max-width: 50%;
  }

  .social-media-container,
  .pages-container,
  .info-container {
    margin-bottom: 30px;
  }
}

@media (max-width: 767px) {
  .mobile-footer-section-container {
    display: block;
    padding-top: 8%;
    padding-left: 10%;
    padding-right: 10%;
    margin-right: 0;
    margin-left: 0;
    background-color: rgba(0, 0, 0, 1);
    color: rgba(255, 255, 255, 1);
  }

  .footer-section-container {
    display: none;
  }

  .footer-section-main-two {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .footer-section-main-three {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
  }

  .get-in-touch {
    text-align: center;
  }

  .social-media-row {
    text-align: center;
  }
  .social-media-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .social-media-container,
  .pages-container,
  .info-container {
    margin-bottom: 30px;
  }
  .social-media-row {
    padding-top: 4%;
  }
}
