.desktop-view {
  display: block;
  padding-left: 0%;
  padding-right: 0%;
}

.mobile-view {
  display: none;
}

.core-section-main {
  padding-bottom: 8%;
}

.heading-row {
  padding-top: 5%;
  padding-bottom: 5%;
}

.core-top-img {
  width: 100%;
}

.content-image-left {
  float: right;
}

.image-col-right {
  background: linear-gradient(70deg, #ffc100 12.02%, #ffe8a0 94.83%),
    lightgray 0px -27.883px / 100% 114.25% no-repeat;
  padding-right: 0%;
  padding-left: 0%;
}
.image-col-left {
  background: linear-gradient(283deg, #ffc100 12.02%, #ffe8a0 94.83%),
    lightgray 0px -27.883px / 100% 114.25% no-repeat;
  padding-right: 0%;
  padding-left: 0%;
}

.core-section-content-col {
  padding-top: 0%;
  padding-bottom: 0%;
}

.core-section-content-col h1 {
  color: #ff881a;
  font-family: Roboto;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 100% */
  letter-spacing: 0.48px;
}

.core-section-content-col h2 {
  color: #181818;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 120% */
}

.core-section-content-col p {
  color: #5a5a5a;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 171.429% */
}

.AA {
  padding-left: 5%;
  padding-right: 12%;
  padding-top: 8%;
}

.BB {
  padding-left: 12%;
  padding-right: 5%;
  padding-top: 8%;
}

@media (max-width: 767px) {
  .desktop-view {
    display: none;
  }

  .mobile-view {
    display: block;
  }

  .core-section-main {
    padding-bottom: 8%;
    padding-left: 8%;
    padding-right: 8%;
  }

  .heading-row {
    padding-top: 5%;
    padding-bottom: 5%;
    padding-left: 0%;
    padding-right: 0%;
  }

  .core-top-img {
    width: 100%;
  }

  .content-image-left {
    float: right;
  }

  .image-col-right {
    background: linear-gradient(70deg, #ffc100 12.02%, #ffe8a0 94.83%),
      lightgray 0px -27.883px / 100% 114.25% no-repeat;
    padding-right: 0%;
    padding-left: 0%;
  }
  .image-col-left {
    background: linear-gradient(283deg, #ffc100 12.02%, #ffe8a0 94.83%),
      lightgray 0px -27.883px / 100% 114.25% no-repeat;
    padding-right: 0%;
    padding-left: 0%;
  }

  .core-section-content-col {
    padding-top: 0%;
    padding-bottom: 0%;
  }

  .core-section-content-col h1 {
    color: #ff881a;
    font-family: Roboto;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 100% */
    letter-spacing: 0.48px;
  }

  .core-section-content-col h2 {
    color: #181818;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 120% */
  }

  .core-section-content-col p {
    color: #5a5a5a;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 171.429% */
  }

  .AA {
    padding-left: 5%;
    padding-right: 12%;
    padding-top: 8%;
  }

  .BB {
    padding-left: 12%;
    padding-right: 5%;
    padding-top: 8%;
  }
}
