.about-section-container {
  display: block;
  background-color: #ffffff;
  padding-top: 12%;
  padding-bottom: 12%;
}
.about-mobile-section-container {
  display: none;
}

.about-left-container-col {
  padding-left: 10%;
  padding-right: 10%;
}

.about-description {
}

.about-left-container h1 {
  padding-top: 10%;
  color: #000;
  font-family: Lato;
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: 51px; /* 159.375% */
}

.about-left-container p {
  color: #333;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px; /* 162.5% */
}

.about-left-container {
  // flex-basis: max-content;
}

.about-section-buttons {
  display: flex;
  flex-direction: row;
  gap: 2%;
  justify-content: flex-start;
  align-items: flex-start;
}

.about-right-container {
  background: linear-gradient(70deg, #ffc100 12.02%, #ffe8a0 94.83%);
  padding-left: 0%;
}

.about-img {
  flex-basis: max-content;
}

@media (max-width: 767px) {
  .about-mobile-section-container {
    display: block;
  }

  .about-section-container {
    display: none;
    background-color: #ffffff;
    padding-top: 12%;
    padding-bottom: 12%;
  }

  .about-description {
  }

  .about-left-container h1 {
    padding-top: 10%;
    color: #000;
    font-family: Lato;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 51px; /* 159.375% */
  }

  .about-left-container p {
    color: #333;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px; /* 162.5% */
  }

  .about-left-container {
    // flex-basis: max-content;
  }

  .about-section-buttons {
    display: flex;
    flex-direction: row;
    gap: 2%;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .about-right-mobile-container {
    // background: linear-gradient(70deg, #ffc100 12.02%, #ffe8a0 94.83%);
    justify-content: center;
    display: flex;
  }

  .about-img {
    flex-basis: max-content;
  }
  // .about-right-container {
  //   display: none;
  // }
}

@media (max-width: 1280px) {
}

@media (max-width: 992px) {
}
